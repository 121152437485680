import {Exception} from "sass";

export function basicFetch(method, path, body, token) {
    let request = {
        mode: "cors",
        method,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    };
    if (body) {
        request.body = JSON.stringify(body);
    }

    console.log(`Calling fetch ${method} ${path}`);
    return fetch(process.env.REACT_APP_BACKEND_HOST + path, request)
        .then(response => {
            if (response.status < 200 || response.status >= 400) {
                console.warn(`Received non-ok status from ${method} ${path} status=${response.status})`);
                throw new Exception();
            }
            return response;
        });
}

export function getAsJson(path, token) {
    return basicFetch("GET", path, null, token)
        .then(response => response.json());
}
export function putAsJson(path, body, token) {
    return basicFetch("PUT", path, body, token)
        .then(response => response.json());
}
export function postAsJson(path, body, token) {
    return basicFetch("POST", path, body, token)
        .then(response => response.json());
}
export function deleteAsJson(path, token) {
    return basicFetch("DELETE", path, null, token)
        .then(response => response.json());
}
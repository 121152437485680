import React, {useState} from "react";

import "./AbilityScoreAssignment.scss";
import "./FeatureAccordion.scss";

import {Input, UncontrolledTooltip} from "reactstrap";
import * as Api from "../../api/Api";
import {POINT_BUY_TABLE} from "../../utils";
import FeatureAccordionItem from "./FeatureAccordionItem";

const AbilityScoreAssignment = ({ character, setCharacter, features, species, skills, classes, feats, talents, token, submit }) => {
    const [saving, setSaving] = useState(false);

    const scoreNames = {
        STR: "Strength",
        DEX: "Dexterity",
        CON: "Constitution",
        INT: "Intelligence",
        WIS: "Wisdom",
        CHA: "Charisma"
    };

    if (!character?.abilities || !Object.keys(character.abilities).length) {
        return <div>Something went wrong!</div>;
    }

    const baseScores = {};
    const scores = {};
    const modifications = {};

    Object.entries(character.abilities).forEach(a => {
        baseScores[a[0]] = a[1].ability.baseScore;
        scores[a[0]] = a[1].score;
        modifications[a[0]] = a[1].modifications;
    });

    const pointsFn = (s) => Object.entries(s)
        .map(e => POINT_BUY_TABLE[e[1]])
        .reduce((partialSum, a) => partialSum + a, 0);
    const points = pointsFn(baseScores);

    const isValidScore = (opts) => {
        if (baseScores[opts.id] === opts.score) {
            return true;
        }
        const potentialScores = {...baseScores};
        potentialScores[opts.id] = opts.score;
        const potentialPoints = pointsFn(potentialScores);
        return (25 - potentialPoints) >= 0;
    }

    const remainingPoints = 25 - points;

    const optionsFor = (id) => {
        return Object.entries(POINT_BUY_TABLE)
            .filter(e => isValidScore({id, score: parseInt(e[0])}))
            .map(e => {
                const key = id + e[0];
                return (
                    <option key={key}>
                        {e[0]}
                    </option>
                )
            });
    }

    const modificationsFor = (id) => {
        if (!modifications[id]) {
            return null;
        }
        const modEntryList = [];
        Object.entries(modifications[id]).forEach(e => {
            const sourceId = e[0];
            const modification = e[1];
            const feature = character.features.find(f => f.id === sourceId);
            if (feature) {
                let source;
                if (feature.sourceId.startsWith("species")) {
                    source = species.find(s => s.id === feature.sourceId)?.name;
                } else {
                    source = feature.feature.name;
                }
                if (source) {
                    let value;
                    if (modification >= 0) {
                        value = "+" + modification;
                    } else {
                        value = "" + modification;
                    }
                    modEntryList.push({ source, value });
                }
            }
        });

        return modEntryList;
    }

    const total = (id) => {
        let summary = [{ source: "Starting Value", value: baseScores[id]}];
        const modifications = modificationsFor(id);
        if (modifications?.length) {
            summary.push(...modifications);
        }
        const controlId = `score-${id}`;
        return (
            <div className="score-total" id={controlId}>
                <span>Total: </span>
                <span className="total-value">{scores[id]}</span>
                <UncontrolledTooltip target={controlId}>
                    <div className="score-summary-tooltip">
                        {summary.map(m => <span className="modification">{m.source}: {m.value}</span>)}
                    </div>
                </UncontrolledTooltip>
            </div>
        );
    };

    const scoreSelector = (id) => (
        <div key={`score-selector-${id}`} className="score-selector">
            <span>{scoreNames[id]}</span>
            <Input
                className="score-selector-input"
                type="select"
                disabled={saving}
                value={baseScores[id]}
                onChange={(evt) => {
                   character.abilities[id].ability.baseScore =
                       parseInt(evt.target.value);
                   setSaving(true);
                   Api.saveCharacter(character, token)
                       .then(data => {
                           setCharacter(data);
                           setSaving(false);
                       });
               }}
            >;
                {optionsFor(id)}
            </Input>
            {total(id)}
        </div>
    );

    const asiGroups = character.features.filter(f => f.feature.id === "feature.AbilityScoreIncrease");

    const props = {
        featureType: "abilityScoreIncrease",
        classes,
        skills,
        feats,
        talents,
        features,
        character,
        changeFeature: (data) => {
            submit(null, ['choice', data]);
        }};

    return (
        <div className="ability-score-assignment-container">
            <div className="ability-score-assignment">
                <div className="points-assignment box filled-opaque">
                    <h2>Ability Scores</h2>
                    <span>Points Remaining</span>
                    <span>{remainingPoints}/25</span>
                    <div className="score-selector-row">
                        {Object.keys(scoreNames).map(id => scoreSelector(id))}
                    </div>
                </div>

            </div>
            {asiGroups.map((group, idx) => (
                    <div className="asi-container" key={`asi-choice-box-${group.id}`}>
                        <div className="header box">
                            <div className="title">
                                <span className="name">Ability Score Increase - Level {(idx +1) * 4 }</span>
                            </div>
                            <div className="asi-choice-container">
                                {character.choices.filter(c => c.sourceId === group.id).map((asi, idx) =>
                                    <FeatureAccordionItem mode="raw" hideTable showChoices {...props} feature={{...asi, description: "Ability " + (idx + 1)}} />
                                )}
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default AbilityScoreAssignment;
import {TreeGraph} from "../common/Graph";

export function toGraph(tree, talents, other) {
    const graph = new TreeGraph(tree.id, tree.name);

    tree.features.forEach(talent => {
        graph.add(talent.id);
        if (talent.prereqs?.hasFeature && talent.prereqs.hasFeature.id.startsWith("talent.")) {
            graph.addRelationship(
                talent.prereqs.hasFeature.id,
                talent.id
            );
        } else if (talent.prereqs?.hasFeatures?.length) {
            talent.prereqs.hasFeatures.filter(f => f.id.startsWith("talent.")).forEach(prereq => {
                graph.addRelationship(
                    prereq.id,
                    talent.id
                );
            })
        }
    });
    const lookup = (node) => {
        let talent = talents.find(f => f.id === node.value);
        if (!talent) {
            const reference = other.find(f => f.id === node.value);
            if (!reference) {
                console.warn("Could not find a talent with id " + node.value, talents);
            }
            talent = reference;
        }
        return talent;
    }

    return [{
        id: graph.id,
        title: graph.name,
        tooltip: {
            content: graph.name
        },
        parents: [],
        children: graph.getRootNodes().map(node => toBstData(node, lookup))
    }];
}

function toBstData(node, nodeMapper) {
    const feature = nodeMapper(node);
    return {
        id: feature.id,
        title: feature.name,
        tooltip: {
            content: feature.name
        },
        feature,
        parents: node.parents,
        children: node.children.map(n => toBstData(n, nodeMapper))
    }
}
import React, {useEffect, useState} from "react";
import "./TableSelectionButton.scss";
import {Badge, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";

import { ReactComponent as ListSvg } from "../../assets/list.svg";
import { ReactComponent as TreeSvg } from "../../assets/ChartTree.svg";
import ConfirmDependenciesModal from "./ConfirmDependenciesModal";
import {TalentTree} from "./TalentTrees";


const namify = (string) => {
    if (typeof string !== 'string' || string.length === 0) {
        return string; // Return an empty string if input is not valid
    }
    return "Select " + string.charAt(0).toUpperCase() + string.slice(1);
};

const SelectionTree = ({ character, rows, talents, treeId, onClick }) => {

    const selectedGraph = talents.treeGraphs.find(g => g[0].id === treeId);

    if (selectedGraph) {
        return <TalentTree
            talents={talents}
            validTalents={rows}
            graph={selectedGraph}
            tree={selectedGraph[0]}
            character={character}
            onClick={onClick}
        />;
    }
    return null;
};

const SelectionTable = ({ rows, selectedId, onRowSelect, filterText }) => {
    const [rowSelection, setRowSelection] = useState(selectedId);

    const filteredRows = !!filterText?.trim()?.length ?
        rows.filter(f =>
            (f?.name?.length && f.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (f?.description?.length && f.description.toLowerCase().includes(filterText.toLowerCase()))
        ) : rows;

    const selectRow = (id) => {
        setRowSelection(id);
        onRowSelect(id);
    }
    return (
        <Table className="features-table">
            <thead>
            <tr>
                <th />
                <th>Name</th>
                <th>Description</th>
            </tr>
            </thead>
            <tbody>
            {filteredRows.map(f => {
                    const classNames = ["feature-table-tr"];
                    let badge = null;
                    if (rowSelection === f.id && f.id !== selectedId) {
                        classNames.push("feature-table-tr-selected");
                        badge = <Badge pill color="success">&#x2713;</Badge>
                    } else if (selectedId === f.id) {
                        badge = <Badge pill>-</Badge>
                        classNames.push("feature-table-tr-current-selected");
                    }
                    return (
                        <tr key={f.id} className={classNames.join(" ")} onClick={() => selectRow(f.id)}>
                            <td>{badge}</td>
                            <td>{f.name}</td>
                            <td>{f.description}</td>
                        </tr>
                    );
                }
            )}
            </tbody>
        </Table>
    );
}

const SelectionModal = ({ mode, featureType, feature, features, talents, character, selectedId, close, confirm, fullscreen}) => {
    // const [modalFullscreen, setModalFullscreen] = useState(fullscreen);
    const [filterText, setFilterText] = useState('');
    const [confirmAllowed, setConfirmAllowed] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [tree, setTree] = useState('');
    const [rows, setRows] = useState([...features]);

    useEffect(() => {
        let newRows = features;
        if (featureType === "talent") {
            newRows = features.filter(f => f.args.tree?.toLowerCase() === tree?.toLowerCase());
        }
        setRows(newRows);
    }, [featureType, features, setRows, tree]);

    useEffect(() => {
        if (featureType === "talent" && talents && selectedId) {
            const talent = talents.talents.find(t => t.id === selectedId);
            if (talent) {
                setTree(talent.args.tree);
            }
        }
    }, [featureType, talents, selectedId]);

    let treeFilter = null;
    if (featureType === "talent") {
        const items = talents.trees
            .filter(t => features.find(f => f.args.tree.toLowerCase() === t.id.toLowerCase()))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(opt =>
                <option key={"tree-" + opt.id} value={opt.id}>
                    {opt.name}
                </option>
            );
        items.unshift(<option key={"tree-nochoice"} value={''}/>);

        treeFilter = (
            <div className="tree-filter">
                <span>Tree:</span>
                <Input
                    key={feature.id + "-input"}
                    name={`Select a Talent Tree`}
                    type="select"
                    value={tree}
                    onChange={(evt) => {
                        setTree(evt.target.value);
                        setFilterText('');
                    }}
                >
                    {items}
                </Input>
            </div>
        )
    }

    let searchFilter = null;
    let body;
    if (mode === "tree") {
        body = (
            <SelectionTree
                treeId={tree}
                rows={rows}
                talents={talents}
                character={character}
                items={rows}
                selectedId={selectedId}
                onClick={({ talent, isAvailable}) => {
                    if (isAvailable && talent.id !== selectedId) {
                        confirm(talent.id);
                    }
                }}
                />
        )
    } else {
        body = (
            <SelectionTable
                rows={rows}
                selectedId={selectedId}
                filterText={filterText}
                onRowSelect={id => {
                    setConfirmAllowed(id?.length && id !== selectedId);
                    setSelectedRow(id);
                }}
            />
        );
        searchFilter = (
            <>
                <Input type="search" value={filterText} placeholder="Search" onChange={(e)=> setFilterText(e.target.value)}/>
                <span className="search-icon">🔍</span>
            </>
        );
    }

    const chooseButton = (
        <Button color="primary" onClick={() => confirm(selectedRow)} disabled={!confirmAllowed}>
            Choose
        </Button>
    );


    return (
        <>
            <Modal className={`feature-table-modal ${fullscreen ? "": "modal-sized"}`}
                   centered
                   isOpen={true}
                   toggle={close}
                   size={fullscreen ? null : "xl"}
                   backdrop={!fullscreen}
                   fullscreen={fullscreen}>
                <ModalHeader tag="div" close={null}>
                    <div className="header-row">
                        <h5>{namify(featureType)}</h5>
                        <div className="filters">
                            {treeFilter}
                            {searchFilter}
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className="feature-table-modal-body">
                    {body}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={close}>
                        Cancel
                    </Button>
                    {' '}
                    {mode === "tree" ? null : chooseButton}
                </ModalFooter>
            </Modal>
        </>
    );
}

const TableSelectionButton = ({ mode, featureType, feature, features, talents, character, changeFeature, selectedId, dependencies }) => {
    const [modal, setModal] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(null);

    const closeConfirmationModal = () => setConfirmationModal(null);
    const openConfirmationModal = (confirmChanges) => setConfirmationModal(
        <ConfirmDependenciesModal
            confirmChanges={confirmChanges}
            dependencies={dependencies}
            confirm={() => {
                changeFeature(confirmChanges);
                closeConfirmationModal();
                closeModal();
            }}
            close={closeConfirmationModal}
        />
    );

    const closeModal = () => setModal(null);
    const handleChoiceChange = (value) => {
        if (dependencies?.length) {
            openConfirmationModal({
                feature,
                featureType,
                value
            });
        } else {
            changeFeature({
                feature,
                featureType,
                value
            });
            closeModal();
        }
    };
    const openModal = () => setModal(
        <SelectionModal
            mode={mode}
            featureType={featureType}
            features={features}
            feature={feature}
            talents={talents}
            character={character}
            selectedId={selectedId}
            close={closeModal}
            dependencies={dependencies}
            confirm={handleChoiceChange}
            fullscreen={mode === "tree"}
        />);

    const icon = mode === "tree" ?
        <TreeSvg className="img-button-icon" onClick={openModal}/> :
        <ListSvg className="img-button-icon" onClick={openModal}/>;

    return (
        <>
            {icon}
            {modal}
            {confirmationModal}
        </>
    );
};

export default TableSelectionButton;
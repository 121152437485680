import React, {useState} from "react";
import WarningModal from "./WarningModal";

const ConfirmDependenciesModal = ({ dependencies, confirm, close}) => {
    return (
        <WarningModal isOpen confirm={confirm} close={close} confirmButtonText="Change Anyway">
            Changing this will remove the following:
            {dependencies.map(d => <ul key={d.id || "empty"}>{d.name}</ul>)}
        </WarningModal>
    );
}

export default ConfirmDependenciesModal;
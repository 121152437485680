import React, {useState} from "react";

import { FeatureAccordion } from "./";
import { v4 as uuid } from "uuid";

import { Images } from '../../utils';

import "./ClassCard.scss";
import {Collapse} from "reactstrap";

const ClassCard = ({ character, classLevel, heroicClass, cancel, isMulticlass, canCollapse, canRemove, removeClass, canAdd, addClass, isOpen }) => {
    const [open, setOpen] = useState(isOpen);

    if (!heroicClass) { return null; }

    const addText = classLevel <= 1 ? "Add Class" : "Add Level";
    const removeText = classLevel <= 1 ? "Remove Class" : "Remove Level";

    const toggleCollapsed = () => canCollapse && setOpen(o => !o);
    const add = canAdd ? <span className="cta" onClick={() => addClass(heroicClass)}>{addText}</span> : null;
    const remove = canRemove ? <span className="cta" onClick={() => removeClass(heroicClass)}>{removeText}</span> : null;
    const cta = canRemove ? remove : add;

    let features = heroicClass.features
        .filter(f => f.args?.level === classLevel);
    if (isMulticlass) {
        features = features.filter(f => !!f.args?.multiclass);
    } else {
        features = features.filter(f => !f.args?.multiclass || f.args?.multiclass === "any");
    }

    if (classLevel % 4 === 0) {
        features.push({
            $ref: null,
            args: {choice: 'abilityScore', choiceFilter: {}, level: classLevel},
            description: "Every fourth level (4th, 8th, 12th, and so on), a character increases two different ability scores by +1.",
            features: [],
            id: "feature.AbilityScoreIncrease",
            name: "Ability Score Increase",
            prereqs: null
        });
    }

    features = features.map(f => ({ ...f, uuid: uuid() }));

    const collapsedClass = canCollapse ?
        (open ? "not-collapsed" : "collapsed") :
        "no-collapse";

    const logo = <img className="sm-logo" src={Images.classes[heroicClass.name.toLowerCase()]} alt={heroicClass.name}/>


    return (
        <div className="class-feature-container">
            <div className="class-header box">
                <div className={"class-title " + collapsedClass} onClick={toggleCollapsed}>
                    {logo}
                    <div className="class-name-flex">
                        <span className="class-name">{heroicClass.name}</span>
                    </div>
                    {cta}
                    {cancel}
                </div>
                <Collapse className="class-card-collapse" isOpen={!canCollapse || open}>
                    <div className="class-details">
                        <span className="class-description">{heroicClass.description}</span>
                        <img src={Images.classes[heroicClass.name.toLowerCase()]} alt={heroicClass.name}/>
                    </div>

                    <FeatureAccordion
                        character={character}
                        features={features}
                        defaultOpen={[]}
                    />
                </Collapse>
            </div>
        </div>
    );
}

export default ClassCard;
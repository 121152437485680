import React from "react";

import {
    AbilityScores,
    Defenses,
    HitPoints,
    Inventory,
    LargeBoxWithScore,
    Proficiencies,
    StackBoxes,
    Skills,
    TabbedFeatures,
} from './';

import './Character.scss';

const Character = ({ character, setCharacter, addEquipment, toggleEquipment, removeEquipment, saveNotes, species, classes }) => {

    const points = [
        { label: "Destiny", total: 0 },
        { label: "Force", total: character.maxForcePoints?.max || 0 },
        { label: "Dark Side", total: character.darkSideScore || 0 }
    ];

    return (
        <div className="character-container">
            <div className="character-stats">
                <div className="top-bar">
                    <AbilityScores abilities={character.abilities}/>
                    <StackBoxes boxes={points} />
                    <LargeBoxWithScore skill={character.skills["skill.Initiative"]} />
                    <LargeBoxWithScore skill={character.skills["skill.Perception"]} />
                    <HitPoints hitPoints={character.maxHitPoints || 0} />
                </div>
                <div className="two-column">
                    <div className="column">
                        <Defenses defenses={character.defenses} />
                        <Proficiencies proficiencies={character.proficiencies} />
                        <Inventory equipment={character.equipment}
                                   addEquipment={addEquipment}
                                   toggleEquipment={toggleEquipment}
                                   removeEquipment={removeEquipment}
                        />
                    </div>
                    <div className="column no-flex">
                        <Skills skills={character.skills}/>
                    </div>
                    <div className="column">
                        <TabbedFeatures
                            character={character}
                            setCharacter={setCharacter}
                            saveNotes={saveNotes}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Character;
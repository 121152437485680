import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const WarningModal = ({ isOpen, confirm, close, title, confirmButtonText, children}) => {
    return (
        <Modal isOpen={isOpen} centered toggle={close}>
            <ModalHeader toggle={close}>{title || "Warning"}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={confirm}>
                    {confirmButtonText || "Confirm"}
                </Button>{' '}
                <Button color="secondary" onClick={close}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default WarningModal;
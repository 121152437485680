import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth"

import * as Api from '../api/Api';
import { Menu, SpinnerPage } from "../common";

import MyCharacters from "./components/MyCharacters";

import { auth } from "../firebase"

import "./MyCharacterListApp.scss";

const MyCharacterListApp = () => {
    const navigate = useNavigate();
    const [loadingCount, setLoadingCount] = useState(0);
    const [characters, setCharacters] = useState([]);
    const [species, setSpecies] = useState([]);
    const [classes, setClasses] = useState([]);
    const [user, loading] = useAuthState(auth);

    useEffect( () => {
        // no logged in user
        if (!user) {
            return;
        }
        setLoadingCount(3);

        Api.loadCharacters(user.accessToken)
            .then(data => {
                setCharacters(data);
                setLoadingCount(p => p - 1);
            });
        Api.loadClasses(user.accessToken)
            .then(data => {
                setClasses(data.classes);
                setLoadingCount(p => p - 1);
            });
        Api.loadSpecies(user.accessToken)
            .then(data => {
                setSpecies(data.species);
                setLoadingCount(p => p - 1);
            });

    }, [user]);

    if (loading) {
        return <SpinnerPage />;
    } else if (user === null) {
        navigate("/login");
    } else if (loadingCount > 0) {
        return <SpinnerPage />;
    }

    const handleDelete = (character) => {
        console.log("handleDelete", character.uuid, user.accessToken);
        Api.deleteCharacter(character.uuid, user.accessToken)
            .then(() => Api.loadCharacters(user.accessToken))
            .then(data => {
                    setCharacters(data);
                    setLoadingCount(p => p - 1);
            });
    }

    return (
        <div className="my-characters-app">
            <div className="nav-container welcome">
                <div className="nav-title">Your Characters</div>
                <Menu />
            </div>
            <div className="nav-spacer" />
            <MyCharacters
                characters={characters}
                species={species}
                classes={classes}
                onDelete={handleDelete}
            />
        </div>
    );
}

export default MyCharacterListApp;